<script>
import Layout from '@/router/layouts/main';
import { required } from 'vuelidate/lib/validators';
import { VMoney } from 'v-money';
import { api } from '@/state/services';

export default {
  locales: {
    pt: {
      'Invoices': 'Notas Fiscais',
      'Invoice created successfully.': 'Nota fiscal criada com sucesso.',
      'There was an error creating the invoice.': 'Ocorreu um erro ao criar a nota fiscal.',
      'Fill in all required fields.': 'Preencha todos os campos obrigatórios.',
    },
    es: {
      'Invoices': 'Facturas',
      'Invoice created successfully.': 'Factura creada correctamente.',
      'There was an error creating the invoice.': 'Hubo un error al crear la factura.',
      'Fill in all required fields.': 'Complete todos los campos obligatorios.',
    }
  },
  components: {
    Layout,
  },
  data () {
    return {
      invoice: {
        access_key: '',
      },

      alert: {
        invoice: { type: '', message: '' }
      },

      loading: false,
    }
  },
  directives: { money: VMoney },
  validations: {
    invoice: {
      access_key: { required },
    },
  },
  methods: {
    invoiceSubmit() {
      this.loading = true
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        api
          .post('invoices', {
            access_key: this.invoice.access_key,
          })
          .then(response => {
            if (response.data.status==='success') {
              this.invoice.access_key = '';
              this.$v.$reset();

              this.alert.invoice.type = 'alert-success';
              this.alert.invoice.message = 'A chave de acesso foi adicionada com sucesso.';

              this.$router.push('/invoices');
            } else {
              this.alert.invoice.type = 'alert-danger';
              this.alert.invoice.message = response.data.message;
            }

            this.loading = false;
          })
          .catch(error => {
            if (error) {
              this.loading = false
            }
          });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Invoices') }}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <b-form class="p-3" @submit.prevent="invoiceSubmit">
          <div v-if="alert.invoice.message" :class="'alert ' + alert.invoice.type">{{ t(alert.invoice.message) }}</div>

          <b-form-group id="access_key" :label="t('Informe a Chave de Acesso')" label-for="access_key">
            <b-form-input class="py-4 font-size-17" v-model="invoice.access_key" type="text" :class="{ 'is-invalid': $v.invoice.access_key.$error }" v-mask="'#### #### #### #### #### #### #### #### #### #### ####'"></b-form-input>
            <div v-if="$v.invoice.$error" class="invalid-feedback">
              <span v-if="!$v.invoice.access_key.required">{{ t('Chave de Acesso é obrigatória.') }}</span>
            </div>
          </b-form-group>

          <div class="mt-4">
            <b-button :disabled="this.loading == true || !this.invoice.access_key" type="submit" variant="default">
              {{ t('Send') }}
              <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </Layout>
</template>

<style scoped>

</style>